import React, { useEffect, useState } from "react"
import Layout from "../../../components/layout";
import SeoResults from "../../../components/property-search/seoResults"
import "aos/dist/aos.css";
import Valuation from "@components/valuation/valuation";
import { Form, Col, Container, Row, Accordion } from 'react-bootstrap';
import Select from 'react-select';
import { SmallSearch, PlusDark, Minus, Sort, Map, Alert, Heart, Bedroom, Bathroom, Reception, Kitchen, ArrowDownFlter } from '@components/icon/icon'
import ContactBox from '@components/contact-box/contact-box';
import Slider from 'react-slick';
import Img from 'gatsby-image'
import GetNegotiator from "@components/property-contact/get-property-team";
import PopularSearchDynamic from "../../../components/popular-search-dynamic";
import ScrollAnimation from 'react-animate-on-scroll';
import GetmapLink from "@components/get-map-link";
import NoResultProperties from "../../NoResult/NoResultProperties";
import { UserObjectStoreProvider, SaveItem, getUserobjectData, isAuthenticated, savedSearchParams } from "@starberry/myaccount-website-utils";
import { Explore, EmailAlert, Next, Back, Filters} from '@components/icon/icon';
import NegoDetails from "../for-sale/nego";
// 
import $ from "jquery";
import qs from "qs"
import {
  parseSearchUrl,
  propertyH1
} from "@components/property-search/utils";
import algoliasearch from "algoliasearch/lite"
import {
  connectStats,
  connectInfiniteHits,
  connectHits,
  InstantSearch,
  RefinementList,
  connectSearchBox,
  connectRange,
  Configure,
  ToggleRefinement,
  connectRefinementList,
  ClearRefinements,
  connectSortBy,
  connectMenu,
  connectToggleRefinement,
  Pagination,
  connectPagination,
} from "react-instantsearch-dom"
import {
  GoogleMapsLoader,
  GeoSearch,
  Marker,
  CustomMarker
} from 'react-instantsearch-dom-maps'
import Cookies from 'universal-cookie'
import { isTablet, isMobile } from "react-device-detect"
import { Link } from "gatsby"
import { navigate } from "@reach/router"
import { ShowProcessedImage } from '@components/common/ggfx-client/module/components/show-image';
import imageConfig from "../../../../static/images/config.json"
import "../assets/styles/_index.scss"

let  searchResultsSize = '775x566'
if(isTablet) {
  searchResultsSize = '738x539'
}else if(isMobile) {
  searchResultsSize = '382x279'
}

// 
export const toTitleCase = (text) => {
  let _text = text || '';
  // console.log('_text : ' + _text);
  if ( _text ) {
    _text = _text.trim().split(' ');
    // console.log('_text.length : ' + _text.length);
    if ( _text.length > 0 ) {
      _text = _text.map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
    }
  }
  return _text;
}
const mycookies = new Cookies();
const updateAfter = 500

var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""

// const searchClient = algoliasearch(
//     "CC9EIO92RT",
//     "f25aaa9851bafb4e1f9e3f820b06cb4d"
// )

const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_API_KEY);

const index_name = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`


// 
const styles = {
    control: styles => ({ 
      ...styles, 
      backgroundColor: null,
      paddingLeft: 0,
      border:0,
      minHeight: 26,

      borderRadius: 0,
      outline: 0,
      // fontSize: '1.8rem', //: '2.2rem',
      boxShadow: 'none',
      color: '#4A4A4A',
    }),
    valueContainer: (styles) => ({
        ...styles,
        // fontSize: () => isMobile ? '1.8rem' : '2.2rem',
        paddingLeft: 0
    }),
    dropdownIndicator: (styles) => ({
        ...styles,
        color: "#5D9272"
    }),
    indicatorsContainer: (styles) => ({
        ...styles,
        color: '#5D9272',
    }),
    indicatorSeparator: () => null,
    placeholder: (styles) => ({
        ...styles,
        marginLeft: 0
    })
}

const ArrowDownFlters = () => (
  <div className="down-indicator">
      <ArrowDownFlter />
  </div>
)
const DownIndicator = () => (
    <div className="down-indicator">
        <PlusDark />
    </div>
)
// 

// Property results loop
const avatar = "https://anthonypepe.q.starberry.com/static/10787986af7b8916c76e11dfbe0e9aa6/f5a60/avatar1.png"

const settings = {
    dots: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const InfiniteHits = ({
    hits,
    hasPrevious,
    refinePrevious,
    hasMore,
    refineNext,
    location,
    userObjects,
}) => {
  var url_main = typeof window !== 'undefined' ? window.location.pathname : ''
  var page = url_main.split("/").filter(c => c.includes("page-")).length > 0 ? url_main.split("/").filter(c => c.includes("page-"))[0].split("page-").map(c => c)[1] : 1
  return (

    <>
           {hits.map((hit, i) => {

                // property details url structure
                let uriStr = "";

                if(hit.searchType === "lettings" ) {
                  uriStr = `property-to-rent/`
                }
                // property details url structure
                let negotiator_details = {};

                // Set values dynamically, for example, from user input or another source
                negotiator_details.email = hit.office_email;
                negotiator_details.name = hit.office_name;
                negotiator_details.telephone = hit.office_phone_number;
                //property description
                let text_truncate = function(str, length, ending) {
                    if (length == null) {
                    length = 200;
                    }
                    if (ending == null) {
                    ending = '...';
                    }
                    if (str.length > length) {
                    return str.substring(0, length - ending.length) + ending;
                    } else {
                    return str;
                    }
                };
                
                // convert special characters in string
                function htmlDecode(input){
                    var e = document.createElement('div');
                    e.innerHTML = input;
                    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
                }

                let property_desc = htmlDecode(hit.description)
                // convert special characters in string
                // property description

                return (
                    <>
                      <>                                  
                        <div id={`myRentProp${hit.objectID}`} className="property-box-row child-list" onClick={() => {sessionStorage.setItem('searchindex', `myRentProp${hit.objectID}`)}}>
                            <Row>
                            <Col md="12" lg="6">
                                    <Link onClick={() => mycookies.set('myRentProp', hit.objectID, { path: '/', maxAge: 300 })}
                                    to={`/${uriStr}${hit.slug}-${hit.objectID}/`}
                                    state={{ referrer: location.pathname }}>
                                      <div className="property-slider">
                                          {(hit.status === "Let") || (hit.status === "Under Offer")?
                                           <div className="flagimg text-18-14">
                                            {hit.status}
                                          </div>
                                          : '' }
                                          <Slider {...settings}>
                                              {hit.images.map((image, index) =>{
                                                if(index <= 4) {
                                                  return(
                                                  <div key={index}>
                                                        <img src={hit?.images[0][searchResultsSize]} alt={hit.address+'- Anthony Pepe'} />
                                                  </div>
                                                  )
                                                }
                                              })}
                                          </Slider>
                                      </div>
                                    </Link>
                                    {/*<SaveItem type="property" pid={hit.objectID} userObjects={userObjects} >
                                        <i className="heart">
                                            <Heart />
                                        </i>
                                            </SaveItem>*/}
                                </Col>
                                <Col md="12" lg="6">
                                    <div className="property-content d-flex flex-wrap">
                                        <div className="top">
                                            <h5>
                                                <Link onClick={() => mycookies.set('myRentProp', hit.objectID, { path: '/', maxAge: 300 })}
                                                to={`/${uriStr}${hit.slug}-${hit.objectID}/`}
                                                state={{ referrer: location.pathname }}>
                                                    {htmlDecode(hit.display_address)}
                                                </Link>
                                            </h5>
                                            {
                                                (hit.price === undefined || hit.price === 0) ? "" : <div className="price text-24 bold">£{hit.price.toLocaleString()}
                                                <span> pcm <Link to="/property-services/rent/tenant-fees/">(fees apply)</Link></span>
                                                </div>
                                            }
                                            <div className="bedrooms text-20-16">{hit.title}</div>
                                            <div className='room-list'>
                                              {hit.bedrooms !=null && hit.bedrooms > 0 &&
                                              <div className="room-icons">
                                                <Bedroom/>
                                                <span>{hit.bedrooms} {hit.bedrooms > 1 ? 'Bedrooms' : 'Bedroom'}</span>
                                              </div>
                                                }
                                                {hit.bathroom !=null && hit.bathroom > 0 &&
                                              <div className="room-icons">
                                                <Bathroom/>
                                                <span>{hit.bathroom} {hit.bathroom > 1 ? 'Bathrooms' : 'Bathroom'}</span>
                                              </div>
                                              }
                                              {hit.reception !=null && hit.reception > 0 &&
                                              <div className="room-icons">
                                                <Reception/>
                                                <span>{hit.reception} {hit.reception > 1 ? 'Receptions' : 'Reception'}</span>
                                              </div>
                                              }
                                                            
                                            </div>
                                            <div className="content d-md-none d-lg-block d-none">
                                                <p>{hit.description === undefined ? '' : text_truncate(property_desc)}</p>
                                            </div>

                                        </div>
                                        <div className="bottom mt-auto getNegotiator">
                                            <div className="contact-box-list">
                                                <NegoDetails negotiator={negotiator_details}/>
                                            </div>
                                            <div className="view-details">
                                            <Link onClick={() => mycookies.set('myRentProp', hit.objectID, { path: '/', maxAge: 300 })}
                                                to={`/${uriStr}${hit.slug}-${hit.objectID}/`}
                                                state={{ referrer: location.pathname }}>
                                                  View Details
                                                  </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        </>
                        {((i + 1) % 4) === 0 &&
                        <div className="book-Valuation child-list">   

                        <div className="static-register-withus-module">
                        <div className="left-side">
                        <h6>Property that is valued accurately sells faster.</h6>
                        {/* <p className="text-20-16">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisis ultrices velit ut finibus orci.</p> */}
                        </div>
                        <div className="right-side">
                        <a href="/value-my-property/" /*onClick={()=> { openSharefrndformModal();}}*/ className="btn btn-transparent-white btn-mailing-list">Book a Valuation</a>
                        </div>
                        </div>       
                        </div>
                        }
                    </>
                )
            })
        }
       <Row className="child-list">
        <Col lg={12} className="text-center">
            <div className="load-more-wrap">
              <CustomPaginationRender pagenumber={parseInt(page)} />
            </div>
        </Col>
        </Row>
    </>
  )
}

const CustomPagination = ({
  currentRefinement,
  nbPages,
  refine,
  createURL,
  pagenumber,
}) => {
  const [PageCount, setPageCount] = useState({label:1, value:1});
  useEffect(() => {
    if (parseInt(nbPages) <= parseInt(pagenumber)) {
      $(".page-next").attr("disabled", true)
    } else {
      $(".page-next").attr("disabled", false)
    }

    if (parseInt(pagenumber) == 1) {
      $(".page-back").attr("disabled", true)
    } else {
      $(".page-back").attr("disabled", false)
    }
    setPageCount({label:pagenumber, value:pagenumber})
  }, [pagenumber, nbPages])

  // dont  show pagination if number of pages is below 2
  if (parseInt(nbPages) < 2) return null

  $(document).on("click", ".ais-Pagination-link", function () {
    if (window) {
      window.scroll({
        top: -100,
        behavior: "smooth",
      })
    }
  })

  // Pagination select box
  const paginationselect = (e) => {
    //var page_val = document.getElementById("pagination-select").value

//console.log(e)
    navigate(createURL(e))
    refine(e)

    if (window) {
      window.scroll({
        top: -100,
        behavior: "smooth",
      })
    }
  }
  // Pagination select box

  // Next Pagination Button
  const nextpage = () => {
    var next_page_number = parseInt(pagenumber) + 1
    navigate(createURL(next_page_number))
    refine(next_page_number)

    if (window) {
      window.scroll({
        top: -100,
        behavior: "smooth",
      })
    }
  }
  // Next Pagination Button

  // Previous Pagination Button
  const prevpage = () => {
    var prev_page_number = parseInt(pagenumber) - 1
    navigate(createURL(prev_page_number))
    refine(prev_page_number)

    if (window) {
      window.scroll({
        top: -100,
        behavior: "smooth",
      })
    }
  }
  // Previous Pagination Button
  // const isMobile = useMedia("(max-width: 767px)", false)

  var selectvalues = []
  for (let i = 1; i <= nbPages; i++) {
    selectvalues.push({ value: i, label: i });
  }
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-md-block">
        <button className="page-back text-20 bold" onClick={() => prevpage()}>
        <Back /><span>Back</span>
        </button>
      </div>
      <div className="text-center text-20-16">
        Page
        <Select
        name="page"
        options={selectvalues}
       // placeholder="1"
        styles={styles}
        value={PageCount}
        //defaultMenuIsOpen = {true}
        classNamePrefix={"property-dropdown"}
        //onChange={(e) => {refine(e.value, e.label)}}
        onChange={(e) => {paginationselect(e.value, e.label)}}
        components={{ DropdownIndicator: () => <ArrowDownFlters /> }}
        />
        of {nbPages}
        {/*<Pagination
          showFirst={false}
          showPrevious={false}
          showNext={false}
          ariaPrevious="Previous page"
          first="Next page"
          showLast={false}
          padding={isTablet ? 2 : isMobile ? 1 : 3}
          // defaultRefinement={props.page}
          // padding={isMobile ? 1 : 2}
       />*/}

      </div>
      <div md={3} className="d-md-block">
        <button className="page-next text-20 bold" onClick={() => nextpage()}>
        <span>Next</span> <Next />
        </button>
      </div>
    </div>
  )
}

const CustomPaginationRender = connectPagination(CustomPagination)

const CustomInfiniteHits = connectHits(InfiniteHits)
// Property results loop

// Searchbox
const SearchBox = connectSearchBox(({ currentRefinement, refine}) => {
    return (
        <div className="field-row">          
            <input 
                type="text" 
                name="search"
                className="search-field text-20-16"
                value={currentRefinement.replace(/-/g, ' ')}
                placeholder="Street, area or postcode" 
                onChange={event => refine(event.currentTarget.value.replace(/ /g, "-").toLowerCase())}
            />
            {/* <span className="d-none d-md-block">+ 2 Miles</span> */}
        </div>
    )
})
// Searchbox

const buildArr = [
  { value: "", label: "All Properties" },
  { value: "flat", label: "Flat" },
  { value: "house", label: "House" },
  { value: "maisonette", label: "Maisonette" },
  { value: "property", label: "Property" },
]

// Property type
const buildingSelectbox = ({ items, currentRefinement, refine}) => {
  // Convert currentRefinement to a string for comparison if necessary
  const buildingRefinementValue = currentRefinement ? currentRefinement.toString() : "";
  // Find the option object in building that matches currentRefinement
  let selectedBuildingType = buildArr.find(e => e.value === buildingRefinementValue);

  return (
  <Select
    name="building"
    options={buildArr}
    placeholder="Property Type"
    styles={styles}
    value={selectedBuildingType}
    classNamePrefix={"property-dropdown"}
    onChange={(e) => {refine(e.value, e.label)}}
    components={{ DropdownIndicator: () => <ArrowDownFlters /> }}
  />
)}

const CustombuildingSelectbox = connectMenu(buildingSelectbox)
// Property type

// Min Price
const priceminRange = [
  {
    value: "0",
    label: "No preference"
  },
  {
    value : "500",
    label : "£500 PCM"
  },
  {
    value : "600",
    label : "£600 PCM"
  },
  {
    value : "750",
    label : "£750 PCM"
  },
  {
    value : "1000",
    label : "£1000 PCM"
  },
  {
    value : "1250",
    label : "£1250 PCM"
  },
  {
    value : "1500",
    label : "£1500 PCM"
  },
  {
    value : "2000",
    label : "£2000 PCM"
  },
  {
    value : "2500",
    label : "£2500 PCM"
  },
  {
    value : "3000",
    label : "£3000 PCM"
  },
  {
    value : "3500",
    label : "£3500 PCM"
  },
  {
    value : "4000",
    label : "£4000 PCM"
  },
  {
    value : "4500",
    label : "£4500 PCM"
  },
  {
    value : "5000",
    label : "£5000 PCM"
  }
]

const minpriceSelect = ({
    currentRefinement,
    min,
    max,
    precision,
    refine
}) => {
    let minPriceRange = priceminRange;
    if (currentRefinement.max !== undefined) {
      minPriceRange = priceminRange.filter((x) => parseInt(x.value) < parseInt(currentRefinement.max))
    }
     // Convert currentRefinement.min to a string for comparison if necessary
     const minRefinementValue = currentRefinement.min ? currentRefinement.min.toString() : "";
    // Find the option object in minPriceRange that matches currentRefinement.min
    let selectedMinPrice = minPriceRange.find(e => e.value === minRefinementValue);
    return (
        <Select
            name="price"
            options={minPriceRange}
            defaultValue={currentRefinement.min || ""}
            styles={styles}
            value={selectedMinPrice || ""}
            classNamePrefix={"property-dropdown"}
            placeholder="Min Price"
            onChange={(e) => {
                minpriceval = e.value
                if (e.value === "") {
                    if (maxpriceval) {
                        refine({
                            max: maxpriceval,
                        })
                    } else refine(e.value, e.label)
                } else {
                    refine({
                        min: e.value,
                        max: maxpriceval,
                    })
                }
            }}
            components={{ DropdownIndicator: () => <ArrowDownFlters /> }}
        />
    )
}

const CustomminpriceSelect = connectRange(minpriceSelect)
// Min Price

// Max Price
const pricemaxRange = [
    {
        value: "0",
        label: "No preference"
    },
    {
        value : "500",
        label : "£500 PCM"
    },
    {
        value : "600",
        label : "£600 PCM"
    },
    {
        value : "750",
        label : "£750 PCM"
    },
    {
        value : "1000",
        label : "£1000 PCM"
    },
    {
        value : "1250",
        label : "£1250 PCM"
    },
    {
        value : "1500",
        label : "£1500 PCM"
    },
    {
        value : "2000",
        label : "£2000 PCM"
    },
    {
        value : "2500",
        label : "£2500 PCM"
    },
    {
        value : "3000",
        label : "£3000 PCM"
    },
    {
      value : "3500",
      label : "£3500 PCM"
    },
    {
      value : "4000",
      label : "£4000 PCM"
    },
    {
      value : "4500",
      label : "£4500 PCM"
    },
    {
      value : "5000",
      label : "£5000 PCM"
    }
]

const maxpriceSelect = ({
    currentRefinement,
    min,
    max,
    precision,
    refine
}) => {

    let maxPriceRange = pricemaxRange;
    let maxpricedisable = ''
    if (currentRefinement.min !== undefined) {
      maxPriceRange = pricemaxRange.filter((x) => parseInt(x.value) > parseInt(currentRefinement.min))
    }
    if (currentRefinement.min == "10000000") {
      maxpricedisable = true
    }

    // Convert currentRefinement.max to a string for comparison if necessary
    const maxRefinementValue = currentRefinement.max ? currentRefinement.max.toString() : "";  
    // Find the option object in maxPriceRange that matches currentRefinement.min
    let selectedMaxPrice = maxPriceRange.find(e => e.value === maxRefinementValue);

    return(
        <Select
            name="price"
            options={maxPriceRange}
            defaultValue={currentRefinement.max || ""}
            styles={styles}
            classNamePrefix={"property-dropdown"}
            placeholder="Max Price"
            value={selectedMaxPrice || ""}
            onChange={(e) => {
                maxpriceval = e.value
                if (e.value === "") {
                    if (minpriceval) {
                        refine({
                            min: minpriceval,
                        })
                    } else refine(e.value, e.label)
                } else {
                    refine({
                        min: minpriceval,
                        max: e.value,
                    })
                }
            }}
            components={{ DropdownIndicator: () => <ArrowDownFlters /> }}
        />
    )
}


const CustommaxpriceSelect = connectRange(maxpriceSelect)
// Max Price

// Bedrooms
const bedroomsArr = [
  { value: "", label: "Bedrooms" },
  { value: '1', label: '1+' },
  { value: '2', label: '2+' },
  { value: '3', label: '3+' },
  { value: '4', label: '4+' },
  { value: '5', label: '5+' },
  { value: '6', label: '6+' }
]
const bedroomSelect = ({
    currentRefinement,
    min,
    max,
    precision,
    refine
}) => {

    let bedroomvalselect = ""
    if (currentRefinement.max !== undefined) {
      bedroomvalselect = 0
    } else if (currentRefinement.min !== undefined) {
      bedroomvalselect = currentRefinement.min
    }
    // Convert currentRefinement.min to a string for comparison if necessary
    const bedroomRefinementValue = currentRefinement.min ? currentRefinement.min.toString() : "";
    // Find the option object in bedroom  that matches currentRefinement.min
    let selectedBedroomCount = bedroomsArr.find(e => e.value === bedroomRefinementValue);

    return(
        <Select
            name="price"
            options={bedroomsArr}
            styles={styles}
            classNamePrefix={"property-dropdown"}
            placeholder="Bedrooms"
             value={selectedBedroomCount}
            onChange={(e) => {
                if (e.value === "") {
                    refine(e.value, e.label)
                } else if (e.value === "0") {
                    refine({
                        max: e.value,
                    })
                } else {
                    refine({
                        min: e.value,
                    })
                }
            }}
            components={{ DropdownIndicator: () => <ArrowDownFlters /> }}
        />
    )
}

const CustombedroomSelect = connectRange(bedroomSelect)
// Bedrooms

// Sort by
const SortByPrice = ({ items, currentRefinement, refine }) => (
    <>
      {items.map((item, key) => {
        return (
            <a href="javascript:void(0)" key={key} className={item.class} onClick={event => {
              event.preventDefault();
              refine(item.value);
            }}>
              <Sort /> {item.label}
            </a>
        )
      })}
    </>
)
  
const CustomSortBy = connectSortBy(SortByPrice);
// Sort by

// Maps
const MapResults = (() => {
    return (
       <>
        {/* <Configure 
          hitsPerPage={12}
        /> */}
        <div id="map" className="map">
          <GoogleMapsLoader apiKey="AIzaSyCIjq6rDKmS3ejm3jSwSnJtWMmIQKrPnOk">
            {google => (
                <GeoSearch 
                    google={google}
                    enableRefineOnMapMove={false}
                    initialZoom={9}
                  >
                    {({ hits }) => (
                        <div>
                            {hits.map(hit => (
                                <>
                                  <CustomMarker key={hit.objectID} position={hit._geoloc} hit={hit} className="icon-property_map_marker" />
                                </>
                            ))}
                        </div>
                    )}
                </GeoSearch>
            )}
          </GoogleMapsLoader>
        </div>
      </>
    )
})
// Maps

// No Results
const NoStats = ({ processingTimeMS, nbHits }) => {
    return (
        <>
        {nbHits === 0 &&
            <Row>
                <Col lg={12} className="px-0">                   
                    <NoResultProperties searchType="lettings" />                            
                </Col>
            </Row>
        }
        </>
    )
}

const CustomNoStats = connectStats(NoStats)
// No Results
  
// Results count
const Stats = ({ processingTimeMS, nbHits }) => <>{nbHits}</>

const CustomStats = connectStats(Stats)
// Results count

// Create URL
export const createURL = (props, state) => {
  let myarea = state.menu

  const isDefaultRoute = 
  !state.query &&
  state.page === 1 &&
  state.menu &&
  state.range &&
  !state.range.price &&
  !state.range.bedrooms

  if (isDefaultRoute) {
    return ""
  }

  var areaPath = "in-london/"
  var bedroomPath = ""
  var pricePath = ""
  var pagePath = ""
  var sortPath = ""
  var propertypath = ""

  if (state.query) {
    if (state.query) {
      areaPath = "in-" + encodeURIComponent(decodeURIComponent(state.query)) + "/"
    }
  }

  const queryParameters = {}

  // Property path
  if (state.menu) {
    if (myarea["building"]) {
        propertypath = "type-" + myarea["building"] + "/"
    }
  }
  // Property path

  // Bedrooms & Price range path
  if (state.range) {
    // Bedrooms
    if (state.range.hasOwnProperty('bedrooms') && state.range.bedrooms.max === 0) {
      bedroomPath = "studio/" //queryParameters.price_max = state.range.price.max;
    }
    if (state.range.bedrooms) {
      if (state.range.bedrooms.min) {
        bedroomPath = state.range.bedrooms.min + "-and-more-bedrooms/" //queryParameters.price_max = state.range.price.max;
      }
    }

    // Price
    if (state.range.price) {
      if (state.range.price.min && state.range.price.max) {
        pricePath =
          "between-" + state.range.price.min + "-and-" + state.range.price.max + '/' //queryParameters.price_min = state.range.price.min;
      } else if (state.range.price.max) {
        pricePath = "under-" + state.range.price.max + '/' //queryParameters.price_max = state.range.price.max;
      } else if (state.range.price.min) {
        pricePath = "over-" + state.range.price.min + '/' //queryParameters.price_max = state.range.price.max;
      }
    }
  }
  // Bedrooms & Price range path

    // Sort path
    if(state.sortBy) {
        if( state.sortBy === index_name+"_price_asc" ) {
            sortPath = "sortby-price-asc/";
        }
        if( state.sortBy === index_name+"_price_desc" ) {
            sortPath = "sortby-price-desc/";
        }
    }
    // Sort path

  // Page path
  if (state.page && state.page !== 1) {
    pagePath = `page-${state.page}/`
  }
  // Page path

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: "repeat",
  })
  
  let myUrl = `${areaPath}${propertypath}${bedroomPath}${pricePath}${queryString}${pagePath}${sortPath}`
  
  if ("/" === myUrl.substr(myUrl.length - 1))
    myUrl = myUrl.substr(0, myUrl.length - 1)
  
  return `/property/to-rent/${myUrl}`
}
// Create URL

// URL to searchstate
const searchStateToUrl = (props, searchState) =>
  searchState ? `${createURL(props, searchState)}` : ""

const urlToSearchState = location => {
  if (location) {
    var query = ""
    let pathUri_main = location.pathname.split("/property/to-rent")

    var areaVal = ""
    var bedVal = 10
    var minpriceVal = ""
    var maxpriceVal = ""
    var pageVal = ""
    var sortVal = ""
    var sortVal_filt = ""
    var propertyval = ""

    if (pathUri_main[1]) {
      // following could be regexp
      let pathUri = pathUri_main[1].split("/")
      //lets loop
      for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for area
        if (typeof pathUri[vi] === "undefined") {
          continue
        }

        // Property type
        if (pathUri[vi].indexOf("type-") >= 0) {
          propertyval = pathUri[vi].replace("type-", "")
        }

        // Area
        if (pathUri[vi].indexOf("in-") >= 0) {
          areaVal = pathUri[vi].replace("in-", "")
        }

        // Price
        if (
          pathUri[vi].indexOf("between-") >= 0 ||
          pathUri[vi].indexOf("over-") >= 0 ||
          pathUri[vi].indexOf("under-") >= 0
        ) {
          let priceFilt1 = pathUri[vi].split("over-")
          if (priceFilt1[1]) {
            minpriceVal = priceFilt1[1]
          }
          let priceFilt2 = pathUri[vi].split("under-")
          if (priceFilt2[1]) {
            maxpriceVal = priceFilt2[1]
          }
          let priceFilt3 = pathUri[vi].split("between-")
          if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].split("-and-")
            minpriceVal = priceFilt4[0]
            maxpriceVal = priceFilt4[1]
          }
        }

        // Bedrooms
        if (pathUri[vi].indexOf("-and-more-") >= 0) {
          bedVal = pathUri[vi].replace("-and-more-bedrooms", "")
        }

        if (pathUri[vi].indexOf("studio") >= 0) {
          // its studio bedroom
          bedVal = 0
        }

        // Sort by
        if (pathUri[vi].indexOf("sortby-") >= 0) {
            sortVal_filt = pathUri[vi].replace("sortby-", "")
  
            if(sortVal_filt === "price-asc") {
              sortVal = index_name+"_price_asc"
            }
  
            if(sortVal_filt === "price-desc") {
              sortVal = index_name+"_price_desc"
            }
        }

        // Page
        if (pathUri[vi].indexOf("page") >= 0) {
          pageVal = pathUri[vi].replace("page-", "")
        }
      }
    }
  }

  // Property type
  if (propertyval) {
    query += `&menu[building]=` + propertyval
  }

  // Area
  if (areaVal) {
    areaVal = areaVal.toLowerCase();
    if (areaVal == "london") query += `&query=`
    else query += `&query=` + areaVal
  }

  // Price
  if (minpriceVal) {
    query += `&range[price][min]=` + minpriceVal
  }

  if (maxpriceVal) {
    query += `&range[price][max]=` + maxpriceVal
  }

  // Bedrooms
  if (bedVal == 0) {
    query += `&range[bedrooms][min]=` + 0
  } else if (bedVal < 10) {
    query += `&range[bedrooms][min]=` + bedVal
  }

  // Page
  if (pageVal) {
    query += `&page=${pageVal}`
  }

    // Sort by
    if (sortVal) {
        query += `&sortBy=` + sortVal
    }

  return qs.parse(query)
}
// URL to searchstate

class PropertySearch extends React.Component {
  componentDidMount() {
    $("html, body").scrollTop(0);
  }
    // 
    state = {
      searchState: urlToSearchState(this.props.location),
      userObjects : {},
      showDesc: false,
      excludeUnderOffer: false
    }
  
    componentDidUpdate(prevProps) {
      if (prevProps.location !== this.props.location) {
        this.setState({ searchState: urlToSearchState(this.props.location) })
      }
    }
  
    onSearchStateChange = searchState => {
      clearTimeout(this.debouncedSetState)
        this.debouncedSetState = setTimeout(() => {
          navigate(searchStateToUrl(this.props, searchState), searchState)
        }, updateAfter)
      this.setState({ searchState })
    }
    // 

    componentDidMount() {
        //$("html, body").scrollTop(0);
        if ( (window.location.href).indexOf('property\/to-rent') ) {

          // Infinite properties scroll
          $(window).scroll(function() {

            // if ( $("#mynextbut").length ) {
            //   var top_of_element = $("#mynextbut").offset().top;
            //   var bottom_of_element = $("#mynextbut").offset().top + $("#mynextbut").outerHeight();
            //   var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            //   var top_of_screen = $(window).scrollTop();
          
            //   if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
            //     $("#mynextbut").trigger( "click" );
            //       //window.scrollTo({ top: top_of_element-100, behavior: 'smooth' });
            //       // the element is visible, do something
            //   }
            // }
            
            if ( $("#myprevbut").length ) {
              var top_of_element = $("#myprevbut").offset().top;
              var bottom_of_element = $("#myprevbut").offset().top + $("#myprevbut").outerHeight();
              var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
              var top_of_screen = $(window).scrollTop();
          
              if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
                $("#myprevbut").trigger( "click" );
              }
            }

          });
          // Infinite properties scroll
        }

        // Sortby option price
        $(".property_sortby_desc").click(function() {
            $(".property_sortby_desc").addClass("active");
            $(".property_sortby_asc").removeClass("active");
        })

        $(".property_sortby_asc").click(function() {
            $(".property_sortby_asc").addClass("active");
            $(".property_sortby_desc").removeClass("active");
        })
        // Sortby option price
        $('.popular-searchlist').replaceWith($('.dynamic-popular-search-footer'));

        if(isAuthenticated()){
          const getUserObjects = async () => {
            try {
                const userObjects = await getUserobjectData()
                this.setState({
                  userObjects : userObjects.data.data
                })
                console.log("success");
            } catch (e) {
                console.log("e:", e)
            }
          }
          getUserObjects()
        }
      if (sessionStorage.getItem('searchindex') != null) {
            var idelement = sessionStorage.getItem('searchindex');
            setTimeout(function () {
              var elmnt = document.getElementById(idelement.toString());
              if (elmnt) {      
                elmnt.scrollIntoView();
                sessionStorage.removeItem('searchindex')
              }
            }, 2000);
          }
          else {
            // load on top
            $(document).ready(function () {
              $("html, body").scrollTop(0)
            });
            // load on top
}
    }
    
    // Sales & Rent dropdown
    onChangeSellRent = (e) => {
      // this.props.history.navigate(`/${e.value}`);
      navigate(`/${e.value}/`)
    }
    // Sales & Rent dropdown

    render () {
      const searchParams = parseSearchUrl("lettings", this.props.location.pathname);
      const {excludeUnderOffer} = this.state;
      const h1 = propertyH1(searchParams);
      const areaName = searchParams.areas;
      const pType = searchParams.pType;
      const saveSearchParams = savedSearchParams(searchParams);

      const isPageLoadBg = (typeof this.props.path === "undefined");

        // Property Map display none & block
        const propertyResultsMap = (e) => {
            $(".properties-map").toggleClass("active");
        }
        // property Map display none & block

        // Filter button
        const moreFilter = (e) => {
            $(".property_more_filter").removeClass("active");
            $(".property_hide_filter").addClass("active");
        }

        const hideFilter = (e) => {
            $(".property_hide_filter").removeClass("active");
            $(".property_more_filter").addClass("active");
        }
        // Filter button
        //status Filter
        var status_type =  ["Let", "Under Offer", "To Let"];
        if(excludeUnderOffer) {
            status_type = ["Let", "To Let"]; 
        }  
        let mystatus = '(status:"'+(status_type).join('" OR status:"')+'")'; 
        return (
          <UserObjectStoreProvider>
            <Layout popularSearch='propertyresults' searchtype='rentals'>
                <SeoResults
                  title={h1}
                  searchParams={searchParams}
                  location={this.props.location}
                />
                <InstantSearch
                    indexName={index_name}
                    searchClient={searchClient}
                    searchState={this.state.searchState}
                    onSearchStateChange={this.onSearchStateChange}
                    createURL={createURL}
                    routing="true"
                >
                    <Configure 
                      hitsPerPage={8 }
                      filters={`publish:true AND searchType:lettings AND ${mystatus}`} 
                    />

                    <div className="filter-form search-results-page">
                        <Container>
                        <Row>
                                <form>
                                    <div className="d-none">
                                        {/* <RefinementList
                                            attribute="searchType"
                                            defaultRefinement={["lettings"]}
                                        /> */}
                                    </div>
                                    <Col className="d-none d-md-block filter-type">
                                        {/* <CustomsearchtypeSaleRent attribute="searchType" /> */}
                                        <Select 
                                            options={[
                                                { value: 'property/for-sale/in-london', label: 'Buy' },
                                                { value: 'property/to-rent/in-london', label: 'Rent' }
                                            ]}
                                            defaultValue={{ value: 'lettings', label: 'Rent' }}
                                            styles={styles}
                                            placeholder="Buy"
                                            classNamePrefix={"property-dropdown"}
                                            onChange={this.onChangeSellRent}
                                            components={{ DropdownIndicator: () => <ArrowDownFlters /> }}
                                        />
                                        <span className="filter-seperation"></span>
                                    </Col>
                                    <Col className="filter-loaction">
                                        <SearchBox />
                                    </Col>
                                    <Col className="d-none d-lg-block filter-building">
                                        <CustombuildingSelectbox attribute="building" />
                                    </Col>
                                    <Col className="d-none d-lg-block slct-min-price filter-min-price">
                                        <CustomminpriceSelect attribute="price" />
                                    </Col>
                                    <Col className="d-none d-lg-block slct-max-price filter-max-price">
                                        <CustommaxpriceSelect attribute="price" />
                                    </Col>
                                    <Col className="d-none d-lg-block slct-room filter-room">
                                        <CustombedroomSelect attribute="bedrooms" />
                                    </Col>
                        
                                    <Accordion className="d-lg-none col">
                                    {/* <Accordion.Toggle variant="link" eventKey="1" onClick={handelToggle}>{filterText} <span>{filterText === "More Filters" ? <PlusDark /> : <Minus />}</span></Accordion.Toggle> */}
                                    <Accordion.Toggle variant="link" eventKey="1" className="filter">
                                        <a href="javascript:void(0)" onClick={moreFilter} className="btn-trans text-20-16 property_more_filter m-0 active d-flex align-items-center">Filters <Filters /></a>
                                        <a href="javascript:void(0)" onClick={hideFilter} className="btn property_hide_filter m-0 ">Hide Filters</a>
                                    </Accordion.Toggle>
                                    {/*<Link to="/property/for-sale/in-london/" className="rightmore">For Sale &gt;</Link>*/}
                                    <Accordion.Collapse eventKey="1">
                                        <Row>
                                            {/*<Col xs="12" md="4" className="d-md-none">
                                              <Select 
                                                  options={[
                                                      { value: 'property/for-sale/in-london/', label: 'Buy' },
                                                      { value: 'property/to-rent/in-london/', label: 'Rent' }
                                                  ]}
                                                  defaultValue={{ value: 'lettings', label: 'Rent' }}
                                                  styles={styles}
                                                  placeholder="Buy"
                                                  onChange={this.onChangeSellRent}
                                                  components={{ DropdownIndicator: () => <DownIndicator /> }}
                                              />
                                            </Col>*/}
                                            <Col xs="12" md="4">
                                                <CustombuildingSelectbox attribute="building" />
                                            </Col>
                                            <Col xs="12" md="4">
                                                <CustomminpriceSelect attribute="price" />
                                            </Col>
                                            {/* <Col xs="12" className="d-md-none">
                                                <Select 
                                                    options={[
                                                        { value: '1000000', label: '1000000' },
                                                        { value: '2000000', label: '2000000' },
                                                        { value: '3000000', label: '3000000' },
                                                        { value: '4000000', label: '4000000' },
                                                    ]} 
                                                    styles={styles}
                                                    placeholder="Location Radius"
                                                    components={{ DropdownIndicator: () => <DownIndicator /> }}
                                                />
                                            </Col> */}
                                            <Col xs="12" md="4">
                                                <CustommaxpriceSelect attribute="price" />
                                            </Col>
                                            <Col xs="12" md="4">
                                                <CustombedroomSelect attribute="bedrooms" />
                                            </Col>
                                        </Row>
                                    </Accordion.Collapse>
                                </Accordion>
                                </form>
                                </Row>
                        </Container>
                    </div>

                    <div className="properties-list spacing-bottom-only properties-search-list">
                        <Container>
                        <Row className="child-list">
                            <div className="search-top justify-content-lg-between spacing-40">
                                      <Col md="12" lg="auto">
                                          <h1 className="text-20"><span className="text-20 bold"><CustomStats /></span> {h1} 
                                          {/*<SaveItem  type="search" searchParams={saveSearchParams} userObjects={this.state.userObjects}> </SaveItem>*/}
                                          <div className="sb-myacc icon wishlist-icn">
                                              <i className="explore" onClick={() => { this.setState({ showDesc: !this.state.showDesc }) }}>
                                                  <Explore />
                                              </i>
                                            </div>
                                        </h1>
                                      </Col>
                                      <Col md="12" lg="auto">
                                          <ul className="utils-nav d-flex justify-content-lg-end">
                                              <li>
                                                  <a className="text-20-16 bold utils-nav-item" href="https://anthonypepe-myaccount-dev.q.starberry.com/" /*onClick={(e)=>OpenMap()}*/ target="_blank"><EmailAlert /> Create Email Alert</a>
                                              </li>
                                              <li> 
                                                <div className="includesold">
                                                    <Form.Group controlId="local-news">
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="disabledFieldsetCheck"
                                                            onChange={(e) => { 
                                                                this.setState({excludeUnderOffer : e.target.checked})
                                                            }}
                                                            label="Exclude Under Offer"
                                                        />
                                                    </Form.Group> 
                                                </div> 
                                            </li>
                                              <li>
                                                  <CustomSortBy 
                                                      items={[
                                                          { value: index_name+'_price_asc', label: 'Lowest Price First', class: 'property_sortby_asc text-20-16' },
                                                          //{ value: index_name+'_price_desc', label: 'Highest Price First', class: 'property_sortby_desc active' },
                                                          { value: index_name, label: 'Highest Price First', class: 'property_sortby_desc active text-20-16' },
                                                      ]}
                                                      defaultRefinement={index_name}
                                                  />
                                              </li>
                                              <li>
                                                <GetmapLink/>
                                              </li>
                                              {/* <li>
                                                  <Alert /> Never miss a property! <a href="/" className="green">Create Alert</a>
                                              </li> */}
                                          </ul>
                                      </Col>
                                      {this.state.showDesc &&
                                      <Col md="12" lg="7">
                                      <p className="intro-text-bot">Explore our collection of {h1} in {toTitleCase(areaName.replace(/%20/g, " "))} with Anthony Pepe estate agents. For more details about renting a property, contact one of our estate agents in North London and Hertfordshire.</p>
                                      </Col>
                                      }
                                </div>
                            </Row>
                            <div className="properties-map child-list">
                                <MapResults />
                            </div>

                            <CustomInfiniteHits location={this.props.location} />
                            <CustomNoStats />
                           
                        </Container>
                    </div>
                </InstantSearch>
                {/*<Valuation />*/}
                <section className="dynamic-popular-search-footer">
                  <PopularSearchDynamic searchtype='rentals' txt="sample" searchBedroomfield={searchParams.bedrooms} searchPtype={searchParams.type} Searcharea={searchParams} />
                </section>
            </Layout>
            </UserObjectStoreProvider>
        )
    }
}

export default PropertySearch
